import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/App/Layout';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, CardHeader, Box, Typography } from '@material-ui/core';
import { fetchPartner } from '../../store/reducers/PartnersReducer';
import { selectPartner } from '../../store/selectors/partners';
import { usePartnerAgreements } from '../../store/hooks/agreements';
import LabelledValue from '../../components/Common/LabelledValue';
import AgreementsTable from '../../components/Agreements/AgreementsTable';
import VouchersTable from '../../components/Vouchers/VouchersTable';

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
}));

const PartnerDetailsPage = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const partner = useSelector(selectPartner);
  const { agreements } = usePartnerAgreements(partner);

  useEffect(() => {
    dispatch(fetchPartner(match.params.partnerId));
  }, [dispatch, match]);

  return (
    partner && (
      <Layout backPath="/partners" back title={partner ? partner.name : ''}>
        <Grid container spacing={3} display="flex" className={classes.table}>
          <Grid item xs={12} sm={8}>
            {partner && (
              <Card>
                <CardHeader
                  title="Partner"
                  titleTypographyProps={{
                    variant: 'button',
                    gutterBottom: true,
                  }}
                  subheaderTypographyProps={{
                    variant: 'h5',
                    color: 'textPrimary',
                  }}
                  subheader={partner.name}
                />
                <CardContent>
                  <Box mb={4}>
                    <Typography>{partner.bio}</Typography>
                  </Box>

                  <Box mb={4}>
                    <LabelledValue label="Partner Type">
                      {partner.partner_type && partner.partner_type.join(',')}
                    </LabelledValue>
                  </Box>

                  <Box display="flex" justifyContent="space-between">
                    <LabelledValue label="Address">
                      {partner.street}
                      <br />
                      {partner.city} {partner.zip}
                      <br />
                      {partner.country}
                      <br />
                    </LabelledValue>
                    <LabelledValue label="Email Address">{partner.email || '-'}</LabelledValue>
                    <LabelledValue label="Phone Number">{partner.phone || '-'}</LabelledValue>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid xs={6} item>
                <Card>
                  <CardHeader
                    title="Trainers"
                    subheader={partner.total_trainers}
                    titleTypographyProps={{
                      variant: 'button',
                      gutterBottom: true,
                    }}
                    subheaderTypographyProps={{
                      variant: 'h5',
                      color: 'textPrimary',
                    }}
                  />
                </Card>
              </Grid>

              <Grid xs={6} item>
                <Card>
                  <CardHeader
                    title="Students"
                    subheader={partner.total_students}
                    titleTypographyProps={{
                      variant: 'button',
                      gutterBottom: true,
                    }}
                    subheaderTypographyProps={{
                      variant: 'h5',
                      color: 'textPrimary',
                    }}
                  />
                </Card>
              </Grid>

              <Grid xs={6} item>
                <Card>
                  <CardHeader
                    title="Orders"
                    subheader={partner.total_orders}
                    titleTypographyProps={{
                      variant: 'button',
                      gutterBottom: true,
                    }}
                    subheaderTypographyProps={{
                      variant: 'h5',
                      color: 'textPrimary',
                    }}
                  />
                </Card>
              </Grid>

              <Grid xs={6} item>
                <Card>
                  <CardHeader
                    title="Contracts"
                    subheader={partner.total_contracts}
                    titleTypographyProps={{
                      variant: 'button',
                      gutterBottom: true,
                    }}
                    subheaderTypographyProps={{
                      variant: 'h5',
                      color: 'textPrimary',
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Card>
              <CardHeader
                title={t('Agreements')}
                titleTypographyProps={{
                  gutterBottom: false,
                }}
              />
              <CardContent classes={{ root: classes.customCardRoot }}>
                <AgreementsTable rows={agreements} onRowClick={() => {}} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Card>
              <CardHeader
                title={t('Vouchers')}
                titleTypographyProps={{
                  gutterBottom: false,
                }}
              />
              <CardContent classes={{ root: classes.customCardRoot }}>
                <VouchersTable rows={partner.vouchers} onRowClick={() => {}} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Layout>
    )
  );
};

export default PartnerDetailsPage;
