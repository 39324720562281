import { Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageInput from '../../components/Form/ImageInput';
import TextInput from '../../components/Form/TextInput';
import { Chip } from '../Common/Fields';
import Checkbox from '../Form/Checkbox';
import CountryInput from '../Form/CountryInput';
import StateInput from '../Form/StateInput';
import PrimaryContactSelect from '../Form/PrimaryContactSelect';

const validate = values => {
  const errors = {};

  return errors;
};

function handleReset() {
  return true;
}

const ProfileForm = ({ data, loading, onSubmit, readonly }) => {
  const { t } = useTranslation();

  const init = {
    image: data.image_small === null ? '' : data.image_small,
    name: data.name === null ? '' : data.name,
    primary_contact_id: data.primary_contact_id === null ? '' : data.primary_contact_id,
    lpi_id: data.lpi_id === null ? '' : data.lpi_id,
    work_phone: data.work_phone === null ? '' : data.work_phone,
    work_email: data.work_email === null ? '' : data.work_email,
    vat: data.vat === null ? '' : data.vat,
    // Billing Address
    street: data.street === null ? '' : data.street,
    zip: data.zip === null ? '' : data.zip,
    city: data.city === null ? '' : data.city,
    state_id: data.state_id === null ? false : data.state_id,
    country: data.country === null ? false : data.country,
    // Shipping Address
    shipping_addr_same_contact: data.shipping_addr_same_contact === null ? true : data.shipping_addr_same_contact,
    shipping_street: data.shipping_street === null ? '' : data.shipping_street,
    shipping_zip: data.shipping_zip === null ? '' : data.shipping_zip,
    shipping_city: data.shipping_city === null ? '' : data.shipping_city,
    shipping_state_id: data.shipping_state_id === null ? false : data.shipping_state_id,
    shipping_country_id: data.shipping_country_id === null ? false : data.shipping_country_id,
  };

  return (
    <Formik initialValues={init} onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
        <Form>
          <Box display="flex" flexDirection="column">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <ImageInput value={values.image} onChange={handleChange('image')} readonly={readonly} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Chip label={t('Partner Type')} value={data.partner_type_slug} many color="default" />
              </Grid>
              {!!data.published && (
                <Grid item xs={12} sm={12}>
                  <Typography variant="body2" gutterBottom>
                    Your public profile on LPI:
                  </Typography>
                  <Link color="textPrimary" href={data.public_profile_url}>
                    {data.public_profile_url}
                  </Link>
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <PrimaryContactSelect
                  name="primary_contact_id"
                  label={t('Primary contact')}
                  error={touched.primary_contact_id && errors.primary_contact_id}
                  onChange={value => setFieldValue('primary_contact_id', value || null)}
                  valueID={values.primary_contact_id}
                  disabled={readonly}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="work_phone"
                  label={t('Phone number')}
                  error={touched.work_phone && errors.work_phone}
                  onChange={handleChange('work_phone')}
                  value={values.work_phone}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  name="work_email"
                  label={t('Email address')}
                  error={touched.work_email && errors.work_email}
                  onChange={handleChange('work_email')}
                  value={values.work_email}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  name="vat"
                  label={t('VAT')}
                  error={touched.vat && errors.vat}
                  onChange={handleChange('vat')}
                  value={values.vat}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2">{t('Billing Address')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextInput
                  name="street"
                  label={t('Address')}
                  error={touched.street && errors.street}
                  onChange={handleChange('street')}
                  value={values.street}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput
                  name="zip"
                  label={t('Postal code')}
                  error={touched.zip && errors.zip}
                  onChange={handleChange('zip')}
                  value={values.zip}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CountryInput
                  name="country"
                  label={t('Country')}
                  error={touched.country && errors.country}
                  onChange={(_e, value) => setFieldValue('country', value?.id || null)}
                  valueID={values.country}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StateInput
                  name="state_id"
                  label={t('State')}
                  error={touched.state_id && errors.state_id}
                  onChange={(_e, value) => setFieldValue('state_id', value?.id || null)}
                  valueID={values.state_id}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                  countryField="country"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput
                  name="city"
                  label={t('City')}
                  error={touched.city && errors.city}
                  onChange={handleChange('city')}
                  value={values.city}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item container xs={12} alignItems="center">
                <Box mr={4}>
                  <Typography variant="h2" display="inline">
                    {t('Shipping Address')}
                  </Typography>
                </Box>
                <Checkbox
                  label={t('Same as Billing')}
                  value={values.shipping_addr_same_contact}
                  mb={0}
                  onChange={handleChange('shipping_addr_same_contact')}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>

              {!values.shipping_addr_same_contact && (
                <React.Fragment>
                  <Grid item xs={12} sm={8}>
                    <TextInput
                      name="shipping_street"
                      label={t('Shipping Address')}
                      error={touched.shipping_street && errors.shipping_street}
                      onChange={handleChange('shipping_street')}
                      value={values.shipping_street}
                      disabled={readonly}
                      inputProps={{
                        readOnly: readonly,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      name="shipping_zip"
                      label={t('Shipping Postal code')}
                      error={touched.shipping_zip && errors.shipping_zip}
                      onChange={handleChange('shipping_zip')}
                      value={values.shipping_zip}
                      disabled={readonly}
                      inputProps={{
                        readOnly: readonly,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CountryInput
                      name="shipping_country_id"
                      label={t('Shipping Country')}
                      error={touched.shipping_country_id && errors.shipping_country_id}
                      onChange={(_e, value) => setFieldValue('shipping_country_id', value?.id || null)}
                      valueID={values.shipping_country_id}
                      disabled={readonly}
                      inputProps={{
                        readOnly: readonly,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StateInput
                      name="shipping_state_id"
                      label={t('Shipping State')}
                      error={touched.shipping_state_id && errors.shipping_state_id}
                      onChange={(_e, value) => setFieldValue('shipping_state_id', value?.id || null)}
                      value={values.shipping_state_id}
                      disabled={readonly}
                      inputProps={{
                        readOnly: readonly,
                      }}
                      countryField="shipping_country_id"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      name="shipping_city"
                      label={t('Shipping City')}
                      error={touched.shipping_city && errors.shipping_city}
                      onChange={handleChange('shipping_city')}
                      value={values.shipping_city}
                      disabled={readonly}
                      inputProps={{
                        readOnly: readonly,
                      }}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Box>
          <DialogActions>
            {!readonly && (
              <>
                <Button onClick={handleReset} color="default">
                  {t('Reset')}
                </Button>
                <Button disabled={loading} onClick={handleSubmit} color="secondary">
                  {t('Submit')}
                </Button>
              </>
            )}
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(ProfileForm);
